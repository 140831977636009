import { CAlert, CCol } from "@coreui/react";
import "../styles/sidebar.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ImageGallery from "./ImageGallary";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const OtherImageGallary = (props) => {
  const [imageData, setImageData] = useState([]);
  const [loader, setLoader] = useState(false);

  async function imageUrlToBase64(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Base64:", error);
    }
  }
  function base64ToUrl(base64) {
    // Convert Base64 string to a Blob
    const byteCharacters = atob(base64.split(",")[1]); // Decode Base64 string
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(
        offset,
        Math.min(offset + 1024, byteCharacters.length)
      );
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/jpeg" }); // Specify MIME type based on the image format (e.g., image/png, image/jpeg, etc.)

    // Create a URL for the Blob
    return URL.createObjectURL(blob);
  }

  const handleAddImage = (imageUrl) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // To handle cross-origin requests if necessary

    imageUrlToBase64(imageUrl)
      .then((base64) => {
        img.src = base64;

        img.onload = () => {
          // Ensure image is loaded before calculating width and height
          const newImage = {
            src: img.src,
            id: `${imageUrl}-${imageUrl.length}`,
            x: 50,
            y: 50,
            width: img.naturalWidth, // Using naturalWidth and naturalHeight
            height: img.naturalHeight,
          };
          props.setOtherImages((prevImages) => [...prevImages, newImage]);
        };

        img.onerror = () => {
          console.error("Failed to load image:", imageUrl);
        };
      })
      .catch((error) => console.error("Error:", error));
  };
  useEffect(() => {
    getImageData(props.type);
  }, [props.type]);
  const getImageData = async (type) => {
    setImageData([]);
    setLoader(true);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/croma_signed_url",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        bucket_name: "croma_images",
        folder_name: type,
      },
    };

    try {
      // let response = tempResp;
      let response = await axios(config);
      console.log("response get", response);
      if (response != undefined) {
        let base64Arr = await convertUrlsToBase64(response.data.signed_urls);
        setImageData(base64Arr);
        setLoader(false);
      }
    } catch (error) {
      console.log("data error", error);
      setLoader(false);
    }
  };
  async function convertUrlsToBase64(inputArray) {
    const fetchBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    };

    const convertedArray = await Promise.all(
      inputArray.map(async (item) => {
        const base64Url = await fetchBase64(item.url);
        return {
          ...item,
          url: base64Url,
        };
      })
    );

    return convertedArray;
  }

  return (
    <>
      <CCol className="w-100 position-relative">
        {loader ? (
          <div className="gallery">
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
            <div className="galleryItem d-flex flex-column position-relative">
              <CCol>
                <SkeletonTheme baseColor="#d4e8ffb8" highlightColor="#73a9ff">
                  <Skeleton
                    width={100}
                    height={100}
                    className="skeleton-loader"
                  />
                </SkeletonTheme>
              </CCol>
            </div>
          </div>
        ) : imageData.length == 0 ? (
          <CAlert className="px-2 py-1 m-2 mt-3">
            No{" "}
            {props.type == "backdrop_images"
              ? "Backdrop Images"
              : props.type == "fashion"
              ? "Product Images"
              : props.type == "logo_images"
              ? "Logo Images"
              : props.type == "furniture"
              ? "Stickers"
              : "Text"}{" "}
            available.
          </CAlert>
        ) : (
          <ImageGallery
            id={"otherImages"}
            images={imageData}
            setBackgroundImage={props.setBackgroundImage}
            handleAddImage={handleAddImage}
            type={props.type}
          />
        )}
      </CCol>
    </>
  );
};

export default OtherImageGallary;
