import React, { useState, useRef, useEffect } from "react";
import {
  Stage,
  Layer,
  Transformer,
  Group,
  Text,
  Image as KonvaImage,
  Rect,
} from "react-konva";
import { Html } from "react-konva-utils";
import { v4 as uuidv4 } from "uuid";
import useImage from "use-image";
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Konva from "konva";

const BackgroundImage = ({ image, stageSize, onDelete }) => {
  const [bgProps, setBgProps] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    scaleX: 1,
    scaleY: 1,
  });
  const bgRef = useRef();
  const transformerRef = useRef();

  useEffect(() => {
    if (image) {
      const imageAspectRatio = image.width / image.height;
      const stageAspectRatio = stageSize.width / stageSize.height;

      let newWidth, newHeight;

      if (imageAspectRatio > stageAspectRatio) {
        newWidth = stageSize.width;
        newHeight = stageSize.width / imageAspectRatio;
      } else {
        newHeight = stageSize.height;
        newWidth = stageSize.height * imageAspectRatio;
      }

      setBgProps({
        x: (stageSize.width - newWidth) / 2,
        y: (stageSize.height - newHeight) / 2,
        width: newWidth,
        height: newHeight,
        scaleX: 1,
        scaleY: 1,
      });
    }
  }, [image, stageSize]);

  useEffect(() => {
    if (transformerRef.current && bgRef.current) {
      transformerRef.current.nodes([bgRef.current]);
      transformerRef.current.getLayer()?.batchDraw();
    }
  }, []);

  const handleTransform = () => {
    if (bgRef.current) {
      const node = bgRef.current;
      setBgProps({
        x: node.x(),
        y: node.y(),
        width: node.width() * node.scaleX(),
        height: node.height() * node.scaleY(),
        scaleX: 1,
        scaleY: 1,
      });
    }
  };

  return (
    <>
      <KonvaImage
        ref={bgRef}
        image={image}
        {...bgProps}
        draggable
        onTransformEnd={handleTransform}
        onDragEnd={(e) => {
          setBgProps({
            ...bgProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
      />
      <Transformer
        ref={transformerRef}
        boundBoxFunc={(oldBox, newBox) => {
          const aspectRatio = image.width / image.height;
          const width = newBox.width;
          const height = width / aspectRatio;
          return {
            ...newBox,
            height,
          };
        }}
      />
      <Html>
        <div
          style={{
            position: 'absolute',
            top: `${bgProps.y}px`,
            left: `${bgProps.x + bgProps.width - 30}px`,
            background: 'white',
            borderRadius: '50%',
            cursor: 'pointer',
            padding: '4px',
            boxShadow: '0 0 5px rgba(0,0,0,0.3)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <CIcon icon={cilTrash} size="sm" style={{ color: 'red' }} />
        </div>
      </Html>
    </>
  );
};
// Separate ImageElement component
const ImageElement = ({ image, isSelected, onSelect, onDelete }) => {

  console.log("ImageElement", onDelete)
  const [img] = useImage(image.src);
  const imageRef = useRef();
  const transformerRef = useRef();

  useEffect(() => {
    if (transformerRef.current && imageRef.current) {
      if (isSelected) {
        transformerRef.current.nodes([imageRef.current]);
        transformerRef.current.getLayer()?.batchDraw();
      }
    }
  }, [isSelected]);

  return (
    <>
      <Group
        x={image.x}
        y={image.y}
        draggable
        onClick={onSelect}
        onTap={onSelect}
      >
        <KonvaImage
          ref={imageRef}
          image={img}
          width={200}
          height={200}
          draggable
          onClick={onSelect}
          onDragEnd={(e) => {
            image.x = e.target.x();
            image.y = e.target.y();
          }}
          onTransformEnd={(e) => {
            const node = imageRef.current;
            image.scaleX = node.scaleX();
            image.scaleY = node.scaleY();
            image.rotation = node.rotation();
          }}
        />
        {isSelected && (
          <Html>
            <div
              style={{
                position: 'absolute',
                top: '-25px',
                right: '-25px',
                cursor: 'pointer',
                background: 'white',
                borderRadius: '50%',
                padding: '4px',
                boxShadow: '0 0 5px rgba(0,0,0,0.3)'
              }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <CIcon icon={cilTrash} size="sm" style={{ color: 'red' }} />
            </div>
          </Html>
        )}
      </Group>
      {isSelected && <Transformer ref={transformerRef} />}
    </>
  );
};
const TextElement = ({
  textProps,
  isSelected,
  onSelect,
  onChange,
  onRemove,
  isEditing,
  onStartEdit,
  onFinishEdit,
}) => {
  const textRef = useRef();
  const groupRef = useRef();
  const [editableText, setEditableText] = useState(textProps.text);

  const handleDblClick = (e) => {
    e.cancelBubble = true;
    onStartEdit();
  };

  const handleTextChange = (e) => {
    setEditableText(e.target.value);
  };

  const handleBlur = () => {
    onChange({ text: editableText });
    onFinishEdit();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  const getTextWidth = () => {
    return textRef.current ? textRef.current.width() : textProps.width;
  };

  return (
    <Group
      ref={groupRef}
      draggable
      x={textProps.x}
      y={textProps.y}
      rotation={textProps.rotation}
      scaleX={textProps.scaleX}
      scaleY={textProps.scaleY}
      id={textProps.id}
      onClick={onSelect}
      onTap={onSelect}
      onDblClick={handleDblClick}
      onDblTap={handleDblClick}
      onDragMove={(e) => {
        const node = e.target;
        onChange({
          x: node.x(),
          y: node.y(),
        });
      }}
      onTransform={(e) => {
        const node = groupRef.current;
        onChange({
          x: node.x(),
          y: node.y(),
          rotation: node.rotation(),
          scaleX: node.scaleX(),
          scaleY: node.scaleY(),
        });
      }}
    >
      {isEditing ? (
        <Html>
          <input
            type="text"
            value={editableText}
            onChange={handleTextChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              fontSize: `${textProps.fontSize}px`,
              fontFamily: textProps.fontFamily,
              fontStyle: textProps.fontStyle,
              fontWeight: textProps.fontWeight,
              color: textProps.fill,
              border: "none",
              padding: "0px",
              margin: "0px",
              background: "transparent",
              outline: "1px solid blue",
              width: `${Math.max(textProps.width, 50)}px`,
            }}
            autoFocus
          />
        </Html>
      ) : (
        <>
          <Text
            ref={textRef}
            text={textProps.text}
            fontSize={textProps.fontSize}
            fontFamily={textProps.fontFamily}
            fill={textProps.fill}
            width={textProps.width}
            height={textProps.height}
            align={textProps.align}
          />
          {isSelected && (
            <Html>
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  left: `${getTextWidth()}px`,
                  transform: `rotate(${textProps.rotation}deg) scale(${textProps.scaleX}, ${textProps.scaleY})`,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
              >
                <CIcon icon={cilTrash} size="sm" style={{ color: "red" }} />
              </div>
            </Html>
          )}
        </>
      )}
    </Group>
  );
};

const ImageEditor = (props) => {
  const [backgroundImage] = useImage(props.backgroundImage);

  console.log("bg image", backgroundImage)
  const containerRef = useRef(null);
  const stageRef = useRef();
  const transformerRef = useRef();
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [editingText, setEditingText] = useState(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === containerRef.current) {
          const { width, height } = entry.contentRect;
          setStageSize({ width, height });
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleRemoveImage = () => {
    props.setImages(props.images.filter((img) => img.id !== props.selectedId));
    props.setSelectedId(null);
  };

  const handleRemoveText = (id) => {
    props.setTexts(props.texts.filter((text) => text.id !== id));
    props.setSelectedTextId(null);
  };

  const handleDeselect = (e) => {
    if (e.target === e.target.getStage()) {
      props.setSelectedId(null);
      props.setSelectedTextId(null);
      setEditingText(null);
    }
  };

  const updateText = (id, newProps) => {
    props.setTexts(
      props.texts.map((text) =>
        text.id === id ? { ...text, ...newProps } : text
      )
    );
  };

  const handleExport = async (format) => {
    if (!stageRef.current) return;
  
    try {
      const stage = stageRef.current;
      const transformer = stage.findOne("Transformer");
  
      if (transformer) {
        transformer.hide();
      }
  
      // Create a white background layer
      const whiteBackground = new Konva.Rect({
        x: 0,
        y: 0,
        width: stage.width(),
        height: stage.height(),
        fill: "white",
      });
  
      const backgroundLayer = new Konva.Layer();
      backgroundLayer.add(whiteBackground);
      stage.add(backgroundLayer);
      backgroundLayer.moveToBottom();
      stage.batchDraw();
  
      // Small delay to ensure the white background is rendered
      await new Promise((resolve) => setTimeout(resolve, 100));
  
      try {
        let dataUrl;
        switch (format) {
          case "png":
            dataUrl = stage.toDataURL({
              pixelRatio: 2,
              mimeType: "image/png",
              quality: 1,
            });
            break;
          case "jpg":
            dataUrl = stage.toDataURL({
              pixelRatio: 2,
              mimeType: "image/jpeg",
              quality: 0.9,
            });
            break;
          case "svg":
            const stageContent = stage.container().innerHTML;
            const svgBlob = new Blob([stageContent], { type: "image/svg+xml" });
            dataUrl = URL.createObjectURL(svgBlob);
            break;
          default:
            dataUrl = stage.toDataURL({ pixelRatio: 2 });
        }
  
        const link = document.createElement("a");
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
        link.download = `canvas-${timestamp}.${format}`;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        if (format === "svg") {
          URL.revokeObjectURL(dataUrl);
        }
      } catch (error) {
        console.error("Export error:", error);
        alert("Export failed. Please ensure all images are properly loaded.");
      } finally {
        // Remove the background layer after export
        backgroundLayer.destroy();
        stage.batchDraw();
      }
  
      if (transformer) {
        transformer.show();
      }
      stage.batchDraw();
    } catch (error) {
      console.error("Stage operation error:", error);
      alert("An error occurred during export. Please try again.");
    }
  };
  

  // New function to handle PDF export
  const handlePDFExport = async () => {
    if (!stageRef.current) return;

    try {
      const stage = stageRef.current;
      const transformer = stage.findOne("Transformer");

      // Hide transformer temporarily
      if (transformer) {
        transformer.hide();
      }

      // Get the stage container
      const container = stage.container();

      // Use html2canvas to create an image
      const canvas = await html2canvas(container, {
        scale: 2, // Increase quality
        logging: false,
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
      });

      // Get the canvas data
      const imgData = canvas.toDataURL("image/png", 1.0);

      // Create PDF
      const pdf = new jsPDF({
        orientation: stage.width() > stage.height() ? "landscape" : "portrait",
        unit: "mm",
      });

      // Calculate dimensions to maintain aspect ratio
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Add image to PDF with calculated dimensions
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Save the PDF
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      pdf.save(`canvas-${timestamp}.pdf`);

      // Show transformer again
      if (transformer) {
        transformer.show();
      }
      stage.batchDraw();
    } catch (error) {
      console.error("PDF export error:", error);
      alert("Failed to generate PDF. Please try again.");
    }
  };

  useEffect(() => {
    const stage = stageRef.current;
    const transformer = transformerRef.current;

    if (transformer) {
      const selectedNode =
        props.selectedId || props.selectedTextId
          ? stage.findOne(`#${props.selectedId || props.selectedTextId}`)
          : null;

      transformer.nodes(selectedNode ? [selectedNode] : []);
      transformer.getLayer()?.batchDraw();
    }
  }, [props.selectedId, props.selectedTextId, props.images, props.texts]);

  return (
    <div ref={containerRef} style={{
      width: "100%",
      height: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      padding: "0px",
      boxSizing: "border-box",
    }}>
      <CCol
        style={{
          backgroundColor: "rgb(139 123 192)",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          position: "sticky",
          zIndex: 3000,
          top: 0,
          height: "30px",
        }}
        className="w-100 m-0 p-0 px-2 py-2 d-flex align-items-center justify-content-between"
      >
        <span style={{ color: "white", fontWeight: "500", fontSize: "15px" }}>
          Image Editor
        </span>
        <div>
          <CDropdown className="d-inline-block">
            <CDropdownToggle
              color="success"
              className="p-0 px-2"
              style={{ fontSize: "14px" }}
            >
              Export
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem onClick={() => handleExport("png")}>
                Download as PNG
              </CDropdownItem>
              <CDropdownItem onClick={() => handleExport("jpg")}>
                Download as JPG
              </CDropdownItem>
              {/* <CDropdownItem onClick={() => handlePDFExport()}>
                Download as PDF
              </CDropdownItem> */}
            </CDropdownMenu>
          </CDropdown>
        </div>
      </CCol>
      <CCol className="m-1" style={{ border: "1px solid lightgray" }}>
        <Stage
          ref={stageRef}
          width={stageSize.width}
          height={stageSize.height}
          onMouseDown={handleDeselect}
        >
          <Layer>
            {backgroundImage && (
              <BackgroundImage
                image={backgroundImage}
                stageSize={stageSize}
                  onDelete={() => props.setBackgroundImage(null)} // Update to handle removing the background image

              />
            )}
            {/* {backgroundImage && (
              <Rect
                x={0}
                y={0}
                width={stageSize.width}
                height={stageSize.height}
                fillPatternImage={backgroundImage}
                fillPatternRepeat="no-repeat"
                // fillPatternScale={{
                //   x: Math.min(
                //     (stageSize.width - 40) / backgroundImage.width,
                //     (stageSize.height - 40) / backgroundImage.height
                //   ),
                //   y: Math.min(
                //     (stageSize.width - 40) / backgroundImage.width,
                //     (stageSize.height - 40) / backgroundImage.height
                //   ),
                // }}
              />
            )} */}

            {props.images.map((image) => (
              <ImageElement
                key={image.id}
                image={image}
                isSelected={image.id === props.selectedId}
                onSelect={() => props.setSelectedId(image.id)}
                onDelete={handleRemoveImage}
              />
            ))}

            {props.texts.map((text) => (
              <TextElement
                key={text.id}
                textProps={text}
                isSelected={text.id === props.selectedTextId}
                onSelect={() => props.setSelectedTextId(text.id)}
                onChange={(newProps) => updateText(text.id, newProps)}
                onRemove={() => handleRemoveText(text.id)}
                isEditing={editingText === text.id}
                onStartEdit={() => setEditingText(text.id)}
                onFinishEdit={() => setEditingText(null)}
              />
            ))}

            <Transformer ref={transformerRef} />
          </Layer>
        </Stage>
      </CCol>
    </div>
  );
};

export default ImageEditor;
