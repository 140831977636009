import React, { useRef } from "react";
import {
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CInputGroup,
  CFormInput,
  CInputGroupText,
  CCard,
} from "@coreui/react";
import { FaCalendarAlt } from "react-icons/fa";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export const DateRangePickerCust = (props) => {
  const ref = useRef(null);
  const today = new Date(); // Current date for minDate
  const [startDate, endDate] = props.dateRange || ["", ""];
  const defaultRange = {
    startDate: startDate ? new Date(startDate) : today,
    endDate: endDate ? new Date(endDate) : today,
    key: "selection",
  };

  const handleDateChange = (item) => {
    const sdate = new Date(item.selection.startDate);
    const edate = new Date(item.selection.endDate);

    // Sync state with parent component
    props.setState([item.selection]);
  };

  const handleDoneClick = () => {
    const sdate = format(props.state[0].startDate, "yyyy-MM-dd");
    const edate = format(props.state[0].endDate, "yyyy-MM-dd");

    props.setDateRange([sdate, edate]);

    if (props.selectedStartDate !== undefined) {
      props.setSelectedDateRange([sdate, edate]);
    }

    props.setIsPickerOpen(false);
  };

  const handleCancelClick = () => {
    props.setIsPickerOpen(false);
  };

  return (
    <CDropdown
      className="rounded"
      autoClose="outside"
      direction="dropend"
      visible={props.isPickerOpen}
      onHide={() => props.setIsPickerOpen(false)}
      onShow={() => props.setIsPickerOpen(true)}
    >
      <CDropdownToggle
        className="p-0 rounded overflow-hidden mt-0"
        caret={false}
      >
        <CInputGroup className="datetextinput-container rounded">
          <CInputGroupText>
            <FaCalendarAlt style={{ color: "rgb(139 123 192)" }} />
          </CInputGroupText>
          <CFormInput
            className="datetextinput"
            size="sm"
            style={{ width: "115px", textAlign: "center" }}
            placeholder="Check In"
            readOnly
            value={startDate || ""}
          />
          <CFormInput
            className="datetextinput"
            size="sm"
            placeholder="Check Out"
            readOnly
            style={{ width: "115px", textAlign: "center" }}
            value={endDate || ""}
          />
        </CInputGroup>
      </CDropdownToggle>

      <CDropdownMenu className="p-2 dropdownmenu">
        <CCard ref={ref}>
          <DateRange
            onChange={handleDateChange}
            showSelectionPreview
            moveRangeOnFirstSelection
            retainEndDateOnFirstSelection
            // months={1}
            ranges={props.state || defaultRange}
            direction="horizontal"
            minDate={today} // Restrict selection to today or later
          />
        </CCard>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            onClick={handleDoneClick}
          >
            Done
          </CButton>
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            onClick={handleCancelClick}
          >
            Cancel
          </CButton>
        </div>
      </CDropdownMenu>
    </CDropdown>
  );
};
