import {
  CSidebar,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CImage,
  CModal,
  CModalHeader,
  CCloseButton,
  CModalBody,
  CCardTitle,
  CTooltip,
  CCol,
  CCollapse,
} from "@coreui/react";
import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import {
  cilCommentSquare,
  cilPowerStandby,
  cilZoom,
  cilZoomIn,
} from "@coreui/icons";
import { useNavigate } from "react-router-dom";
import { TbTargetArrow } from "react-icons/tb";
import { CiCircleQuestion } from "react-icons/ci";
import AtgLogo from "../assets/logos/Atgeir-New-Logo_Dark.svg";
import googleLogo from "../assets/logos/Google_Cloud.png";
import ArcDia from "../pages/SBI/assets/images/Dialogflow-CX-Arch.svg";
import { BiMenuAltRight } from "react-icons/bi";
import { BiMenu } from "react-icons/bi";
import { MdContactSupport } from "react-icons/md";
import { FaHotel } from "react-icons/fa6";
import { BsZoomIn } from "react-icons/bs";
const ItinerarySidebar = (props) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  return (
    <CSidebar className="new-sidebar">
      <div
        className="d-flex align-items-center p-2 mb-1"
        style={{
          boxShadow: " 0 0 6px rgba(102, 102, 102, 0.75)",
          clipPath: "inset(0px 0px -15px 0px)",
        }}
      >
        <CCol className="d-flex justify-content-between align-items-center gap-2 p-0 m-0 pe-2">
          {props.sidebarVis && (
            <CCardTitle className="title-sidebar">
              <FaHotel size={18} className="me-2" />
              {"Hotels"}
            </CCardTitle>
          )}
        </CCol>
      </div>
      <div
        className="d-flex align-items-center p-2 mb-1"
        style={{
          boxShadow: " 0 0 6px rgba(102, 102, 102, 0.75)",
          clipPath: "inset(0px 0px -15px 0px)",
        }}
      >
        <CCol className="d-flex justify-content-between align-items-center gap-2 p-0 m-0 pe-2">
          {props.sidebarVis && (
            <CCardTitle className="title-sidebar">
              <MdContactSupport size={20} className="me-1" />
              {"Support"}
            </CCardTitle>
          )}
        </CCol>
      </div>
    </CSidebar>
  );
};

export default ItinerarySidebar;
