import {
  CButton,
  CCard,
  CCardBody,
  CCardImage,
  CCardText,
  CCardTitle,
  CCol,
  CRow,
} from "@coreui/react";
import na1 from "../../assets/itinerary_image/na1.jpg";
import { Element } from "react-scroll";
import { format } from "date-fns";
import { FaTaxi } from "react-icons/fa6";
import { MdNoMealsOuline } from "react-icons/md";
import { RiHotelFill } from "react-icons/ri";
import { FaMapLocationDot } from "react-icons/fa6";

const DayWiseDetails = (props) => {
  return (
    <CCol id="containerElement">
      {props.generatedData &&
        props.generatedData.map((item, index) => (
          <Element
            name={`Day ${index + 1}`}
            key={`Day ${index + 1}`}
            className="element"
          >
            <CCol
              className=" p-0 p-1"
              style={{
                backgroundColor: "rgb(246 246 246)",
                position: "sticky",
                top: "0px",
              }}
            >
              <div
                className="px-2 pe-3 py-1"
                style={{
                  width: "fit-content",
                  backgroundColor: "#8EC5FC",
                  backgroundImage:
                    "linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Day {index + 1}: {format(new Date(item?.date), "dd MMM")},{" "}
                {item?.dayname.slice(0, 3)}
              </div>
            </CCol>
            <CCol className="mt-2 px-2 ps-3">
              {item?.activities.map((activity, actindex) => (
                <>
                  {/* <div style={{ fontSize: "14px" }} className="mb-1">
                    Time: {activity.timeframe}
                  </div> */}
                  <div
                    className="px-3  d-flex align-items-center gap-2 ms-1"
                    style={{
                      maxWidth: "fit-content",
                      border: "0.5px solid #accbee",
                      fontWeight: "500",
                      color: "rgb(33 106 189)",
                      backgroundImage:
                        "linear-gradient(to top, #accbee 0%, #e7f0fd 100%)",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    {activity.activity_type == "Meal" ? (
                      <MdNoMealsOuline />
                    ) : activity.activity_type == "Nearby" ? (
                      <FaMapLocationDot />
                    ) : (
                      <RiHotelFill />
                    )}
                    <span style={{}}>{activity.activity_type}</span>
                  </div>
                  <CCard
                    className="mb-3"
                    style={{
                      maxWidth: "540px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      position: "relative",
                      backgroundImage:
                        "linear-gradient(to top, #dfe9f3 0%, white 100%)",
                    }}
                  >
                    {/* <div
                      className="px-3 py-1"
                      style={{
                        fontWeight: "500",
                        position: "absolute",
                        right: "0px",
                        borderBottomLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        backgroundImage:
                          "linear-gradient(to top, #accbee 0%, #e7f0fd 100%)",
                        letterSpacing: "0.5px",
                        color: "rgb(33 106 189)",
                      }}
                    >
                      {activity.activity_type}
                    </div> */}
                    <CRow className="g-0">
                      <CCol md={11}>
                        <CCardBody>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#525252",
                            }}
                            className="mb-1"
                          >
                            {activity.activity}
                          </div>
                          <CCardText>{activity.what_to_do}</CCardText>
                          {activity.activity_type == "Nearby" && (
                            <CCol classsName="d-flex justify-content-end mb-1">
                              <CButton
                                color="warning"
                                className="d-flex align-items-center gap-2 px-2 py-1"
                                style={{ fontSize: "14px" }}
                              >
                                <FaTaxi />
                                Book a cab
                              </CButton>
                            </CCol>
                          )}
                        </CCardBody>
                      </CCol>
                    </CRow>
                  </CCard>
                </>
              ))}
            </CCol>
          </Element>
        ))}
    </CCol>
  );
};
export default DayWiseDetails;
