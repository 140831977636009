import { CContainer, CImage, CButton, CCardTitle } from "@coreui/react";
import React from "react";
import { Link } from "react-router-dom";
import AtgLogo from "../assets/logos/Atgeir-New-Logo_Light.svg";
import googleLogo from "../assets/logos/Google_Cloud.png";
import CIcon from "@coreui/icons-react";
import {
  cilAccountLogout,
  cilHamburgerMenu,
  cilPowerStandby,
} from "@coreui/icons";
import { useNavigate } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
// const lookerApi = require("looker-sdk");
export const ItineraryNavbar = (props) => {
  const navigate = useNavigate();

  return (
    <nav className="navbar navbar-expand-lg px-3 navbar-light sticky-top w-100 justify-content-between align-items-center d-flex">
      <div className="d-flex align-items-center gap-2">
        <HiMenu
          size={20}
          onClick={() => props.setSidebarVis(!props.sidebarVis)}
        />

        <Link to={"/home"}>
          <CImage width={100} src={AtgLogo} />
        </Link>
      </div>
      <CCardTitle
        // className="mt-3"
        style={{
          color: "white",
          alignItems: "end",
          fontWeight: 400,
          fontFamily: "system-ui",
          fontSize: "16px",
        }}
      >
        DataGeir™ - GenAI Workbench (Demo Instance)
      </CCardTitle>
      <div className="d-flex align-items-center">
        {/* <CImage width={130} src={googleLogo} /> */}
        <CIcon
          icon={cilPowerStandby}
          className="hover  logout-icon-2"
          width={20}
          onClick={() => {
            navigate("/");
            sessionStorage.clear();
          }}
        />
      </div>
    </nav>
  );
};
