import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CCollapse,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
  CTab,
  CTabContent,
  CTabList,
  CTabPanel,
  CTabs,
} from "@coreui/react";
import "react-loading-skeleton/dist/skeleton.css";

import { Navbar } from "../components/Navbar";
import CommonSidebar from "../components/CommonSidebar";
import arcDiag from "../assets/images/ask2bigquery_arch_dia.png";
import ItinerarySidebar from "../components/ItinerarySidebar";
import { ItineraryNavbar } from "../components/ItineraryNavbar";
import { FaUserAlt } from "react-icons/fa";
import { FaHotel } from "react-icons/fa6";
import { FaCircleDot } from "react-icons/fa6";
import { FaRegCircleDot } from "react-icons/fa6";
import DayWiseDetails from "./ItineraryMandM/DayWiseDetails";
import axios from "axios";

import { DateRangePickerCust } from "../components/DateRangePickerCust";
import { format } from "date-fns";
import { Link } from "react-scroll";
const ItineraryInsightMandM = () => {
  const [visible, setVisible] = useState(true);
  const [generatedData, setGeneratedData] = useState([]);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const messageContainerRef = useRef(null);
  const [hotelData, setHotelData] = useState([
    "Club Mahindra Tungi",
    "Club Mahindra Assonora",
    "Club Mahindra Ashtamudi",
    "Club Mahindra Munnar",
    "Club Mahindra Varca",
    "Club Mahindra Binsar",
    "Club Mahindra Dwarka",
    "Club Mahindra Madikeri",
    "Club Mahindra Alibaug",
    "Club Mahindra Mahabaleshwar",
    "Club Mahindra Mussoorie",
    "Club Mahindra Kaziranga",
  ]);
  const [filteredHotelData, setFilteredHotelData] = useState(hotelData);
  const [hotelSearchTerm, setHotelSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [searchVis, setSearchVis] = useState(false);

  const handleSearchText = (e) => {
    let filtered = hotelData.filter((el) => {
      return el
        .toLocaleLowerCase()
        .includes(e.target.value.toLocaleLowerCase());
    });
    // console.log("filteredData", filtered);
    if (filtered.length == 0) {
      setSearchVis(false);
    } else {
      setSearchVis(true);
    }
    setHotelSearchTerm(e.target.value);
    setFilteredHotelData(filtered);
  };

  const handleSearch = async () => {
    setLoading(true);
    setGeneratedData([]);
    let config = {
      method: "post",
      url: "https://us-central1-generativeai-coe.cloudfunctions.net/itinerary_recommendation",

      headers: {
        "Content-Type": "application/json",
      },
      data: {
        Member_Name: name,
        check_in_date: dateRange[0],
        check_out_date: dateRange[1],
        property_name: hotelSearchTerm,
      },
    };
    try {
      const response = await axios(config);
      console.log("itinery resp", response);
      // let response = true;
      if (response != undefined) {
        let tempData = Object.values(response.data);
        setGeneratedData(tempData);
        console.log(tempData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleSetActive = (to) => {
    console.log(to);
  };
  return (
    <div
      className="font-md position-relative "
      style={{
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
      }}
    >
      <ItineraryNavbar sidebarVis={visible} setSidebarVis={setVisible} />

      <CCol className="d-flex w-100">
        <CCollapse
          horizontal
          visible={visible}
          style={{ width: "20%" }}
          className="m-1 ms-2"
        >
          <div style={{ width: "100%" }}>
            <ItinerarySidebar
              generatedData={generatedData}
              setGeneratedData={setGeneratedData}
              sidebarVis={visible}
              setSidebarVis={setVisible}
              title={"Itinerary"}
              askQuestion={true}
              ArcDia={arcDiag}
            />
          </div>
        </CCollapse>
        <div
          style={{ width: visible ? "80%" : "100%" }}
          className={`m-1 me-2 ${!visible && "ms-2"} position-relative`}
        >
          <div
            className="chat-container d-flex align-items-center flex column"
            ref={messageContainerRef}
          >
            <CCard style={{ width: "80%" }} className="p-4 ">
              <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon1">
                  <FaUserAlt style={{ color: "rgb(139 123 192)" }} />
                </CInputGroupText>
                <CFormInput
                  placeholder="Name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </CInputGroup>
              <CCol className="mb-3">
                <DateRangePickerCust
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isPickerOpen={isPickerOpen}
                  setIsPickerOpen={setIsPickerOpen}
                  state={state}
                  setState={setState}
                />
              </CCol>
              <CCol>
                <div className="m-0 p-0 mb-1">
                  <CInputGroup className="mb-3">
                    <CInputGroupText id="basic-addon1">
                      <FaHotel style={{ color: "rgb(139 123 192)" }} />{" "}
                    </CInputGroupText>
                    <CFormInput
                      style={{ textAlign: "left" }}
                      placeholder="Enter Hotel Name"
                      name="workloadTool"
                      id="workloadTool"
                      type="text"
                      value={hotelSearchTerm}
                      autoComplete={"off"}
                      className="mb-0"
                      onChange={(e) => {
                        handleSearchText(e);
                      }}
                      onFocus={(e) => {
                        if (filteredHotelData.length > 0) setSearchVis(true);
                        handleSearchText(e);
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setSearchVis(false);
                        }, 300);
                      }}
                    ></CFormInput>
                  </CInputGroup>
                  <div className={`${searchVis ? "d-block" : "d-none"}`}>
                    <CDropdown
                      // onHide={() => {
                      //   setSearchVis(false);
                      // }}
                      offset={[-20, 3]}
                      className="p-0 m-0"
                      visible={searchVis}
                      autoClose
                    >
                      <CDropdownMenu
                        className=" p-0 m-0"
                        style={{
                          maxHeight: "120px",
                          overflow: "scroll",
                          top: "-27px",
                          left: "40px",
                          minWidth: "30vw",
                          border: "0.5px solid #eeeeee",
                        }}
                      >
                        {filteredHotelData?.map((el) => {
                          return (
                            <CDropdownItem
                              style={{
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSearchVis(false);
                                setHotelSearchTerm(el);
                              }}
                            >
                              {el}
                            </CDropdownItem>
                          );
                        })}
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                </div>
              </CCol>
              <CCol>
                <CButton
                  color="primary"
                  variant="outline"
                  className="d-flex align-items-center gap-2"
                  onClick={() => handleSearch()}
                >
                  {loading && <CSpinner size="sm" />}
                  Search
                </CButton>
              </CCol>
            </CCard>

            {generatedData.length == 0 && !loading ? (
              <CCard
                style={{
                  width: "80%",
                  position: "sticky",
                  top: 0,
                }}
              >
                <div
                  style={{ color: "grey", fontSize: "18px", fontWeight: "600" }}
                  className="mb-3 mt-3 ps-3"
                >
                  Hotels
                </div>
                <CCol className="d-flex flex-row align-items-center gap-2">
                  <div class="wrapper-hotel">
                    <div class="image-hotel i1"></div>
                    <div class="details-hotel ">
                      <h2>Club Mahindra Tungi Lonavala Resort</h2>
                    </div>
                  </div>

                  <div class="wrapper-hotel">
                    <div class="image-hotel i2"></div>
                    <div class="details-hotel ">
                      <h2>Club Mahindra Assonora Resort in Bardez, Goa</h2>
                    </div>
                  </div>

                  <div class="wrapper-hotel">
                    <div class="image-hotel i3"></div>
                    <div class="details-hotel ">
                      <h2>Club Mahindra Ashtamudi Resort in Kollam, Kerala</h2>
                    </div>
                  </div>
                </CCol>
              </CCard>
            ) : (
              generatedData.length > 0 && (
                <CCard
                  style={{
                    width: "80%",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <CTabs
                    activeItemKey={1}
                    style={{ maxHeight: "600px", overflow: "scroll" }}
                  >
                    {" "}
                    <CTabContent>
                      <CCardHeader
                        style={{
                          position: "sticky",
                          top: "-20px",
                          zIndex: 10,
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        <CTabList
                          variant="underline"
                          style={{ fontSize: "16px" }}
                        >
                          <CTab aria-controls="home-tab-pane" itemKey={1}>
                            Itinerary
                          </CTab>
                        </CTabList>
                        <CCol
                          className="d-flex flex-column py-2"
                          style={{
                            width: "20%",
                            maxWidth: "20%",
                            position: "absolute",
                            top: "85px",
                          }}
                        >
                          <CCardSubtitle className="ps-1 mb-2 text-secondary">
                            Day Plan
                          </CCardSubtitle>
                          {generatedData &&
                            generatedData.map((item, index) => (
                              <Link
                                containerId="containerElement"
                                activeClass="active-date"
                                to={`Day ${index + 1}`}
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                onSetActive={handleSetActive}
                              >
                                <div
                                  className="d-flex align-items-center gap-2 px-2 py-1"
                                  key={index}
                                  style={{
                                    width: "80%",
                                    // backgroundColor: "rgba(139, 123, 192,0.1)",
                                    // border: "1px solid rgb(93, 74, 155)",
                                    // color: "rgb(93, 74, 155)",
                                    // borderTopRightRadius: "10px",
                                    // borderBottomRightRadius: "10px",
                                  }}
                                >
                                  <FaCircleDot
                                    size={10}
                                    style={{ color: "rgb(93, 74, 155)" }}
                                  />
                                  {format(new Date(item?.date), "dd MMM")},{" "}
                                  {item?.dayname.slice(0, 3)}
                                </div>
                              </Link>
                            ))}
                          {/* <div
                        className="d-flex align-items-center gap-2 px-2 py-1"
                        style={{
                          width: "80%",
                          backgroundColor: "rgba(139, 123, 192,0.1)",
                          border: "1px solid rgb(93, 74, 155)",
                          color: "rgb(93, 74, 155)",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <FaCircleDot
                          size={10}
                          style={{ color: "rgb(93, 74, 155)" }}
                        />
                        6 Dec, Mon
                      </div> */}
                        </CCol>
                      </CCardHeader>
                      <CCardBody style={{ position: "sticky", top: 0 }}>
                        <CTabPanel
                          className="py-3"
                          aria-labelledby="home-tab-pane "
                          itemKey={1}
                        >
                          <CCol
                            className="d-flex w-100 "
                            style={{ paddingLeft: "20%" }}
                          >
                            <div className="d-flex" style={{ height: "200px" }}>
                              <div
                                className="vr"
                                style={{ backgroundColor: "rgb(183 185 190)" }}
                              ></div>
                            </div>
                            <CCol>
                              <DayWiseDetails generatedData={generatedData} />
                            </CCol>
                          </CCol>
                        </CTabPanel>
                        <CTabPanel
                          className="py-3"
                          aria-labelledby="profile-tab-pane"
                          itemKey={2}
                        ></CTabPanel>
                        <CTabPanel
                          className="py-3"
                          aria-labelledby="contact-tab-pane"
                          itemKey={3}
                        ></CTabPanel>
                      </CCardBody>{" "}
                    </CTabContent>
                  </CTabs>
                </CCard>
              )
            )}
          </div>
        </div>
      </CCol>
    </div>
  );
};

export default ItineraryInsightMandM;
