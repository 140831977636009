import { CAlert, CForm, CFormInput, CFormLabel, CImage } from "@coreui/react";
import React, { useState } from "react";
import "../styles/login.css";
import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilWarning } from "@coreui/icons";
import AtgLogo from "../assets/logos/Atgeir-New-Logo_Dark.svg";
import googleLogo from "../assets/logos/Google_Cloud.png";
import bgImg from "../assets/images/ai_login_img.jpg";
const Login = () => {
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();
  const handleLogin = (e) => {
    e.preventDefault();
    let email = e.target[0].value.toLowerCase();
    let password = e.target[1].value;
    if (
      (email == "genaidemo@atgeirsolutions.com" &&
        password == "AtgeirAdmin@1234!") ||
      (email == "ashok_leyland_demo@atgeirsolutions.com" &&
        password == "AtgeirAdmin@1234") ||
      (email == "webinardemo@atgeirsolutions.com" &&
        password == "AtgeirAdmin@1234") ||
      (email == "urvashi@atgeirsolutions.com " && password == "Urvashi@1234") ||
      (email == "m&mdemo@atgeirsolutions.com" && password == "M&MDemo@2026!!")
    ) {
      sessionStorage.setItem("authentication", JSON.stringify(email));
      sessionStorage.setItem("region", JSON.stringify("INT"));
      setTimeout(() => {
        navigate("/home");
      }, 100);
    } else {
      setAlert(true);
    }
  };

  return (
    <div className="align-items-center  min-vh-100 d-flex flex-row align-items-center p-0 m-0 relative">
      <div className="auth-wrapper">
        <CImage
          style={{
            // width: "100%",
            height: "100%",
            marginLeft: "30vw",
            zIndex: 0,
            position: "fixed",
          }}
          src={bgImg}
        />
        <div className="auth-inner">
          <CForm
            style={{ padding: "55px 45px 55px 45px" }}
            onSubmit={(e) => {
              handleLogin(e);
            }}
          >
            <b style={{ fontSize: "22px", color: "#1d57ab" }} className="">
              Empowering Innovation with Generative AI
            </b>
            <p className="text-medium-emphasis mb-4 mt-4">
              Welcome Back. please login to your account
            </p>
            <div className="mb-3">
              <CFormLabel className="required mb-0">Email address</CFormLabel>
              <CFormInput
                type="email"
                className="form-control"
                placeholder="Enter email"
                feedbackInvalid="Please enter email"
                required
                // size="sm"
              />
            </div>

            <div className="mb-5">
              <CFormLabel className="required mb-0">Password</CFormLabel>
              <CFormInput
                type="password"
                className="form-control"
                placeholder="Enter password"
                feedbackInvalid="Please enter password"
                required
                // size="sm"
              />
            </div>

            <div className="d-grid mb-5">
              <button class="button-10" role="button" type="submit">
                Log In
              </button>
            </div>

            <CAlert
              visible={alert}
              onClose={() => {
                setAlert(false);
              }}
              dismissible
              color="danger"
            >
              <CIcon icon={cilWarning} /> Wrong Email or Password
            </CAlert>
            <div className="d-flex mt-2 justify-content-between">
              <CImage width={150} src={AtgLogo} />
              <CImage width={150} src={googleLogo} />
            </div>
          </CForm>
        </div>
      </div>
    </div>
  );
};

export default Login;
